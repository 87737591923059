import { Button } from "@/components/common/button"
import { Headline } from "@/components/common/headline"

export default function Error({ reset }: { reset: () => void }) {
  return (
    <div className="container mx-auto p-8">
      <Headline size="md" variant="error">
        Something went wrong!
      </Headline>
      <Button color="primary" title="Reload page" onClick={reset} />
    </div>
  )
}
