"use client"
import BugsnagPerformance from "@bugsnag/browser-performance"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import React from "react"

import { BUGSNAG_API_KEY } from "@/utils/enviroment"

const apiKey = BUGSNAG_API_KEY ?? ""

Bugsnag.start({
  apiKey,
  plugins: [new BugsnagPluginReact(React)],
})
BugsnagPerformance.start({ apiKey })

export default Bugsnag
export const BugsnagErrorBoundary =
  Bugsnag.getPlugin("react")?.createErrorBoundary(React)
