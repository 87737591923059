"use client"
import { useEffect } from "react"

import Error from "./components/Error" // Error components must be Client components

import Bugsnag from "@/config/bugsnag"

export type ErrorBoundaryProps = {
  error: Error & { digest?: string }
  reset: () => void
}

export default function RootErrorBoundary({
  error,
  reset,
}: ErrorBoundaryProps) {
  useEffect(() => {
    Bugsnag.notify(error, (event) => {
      event.severity = "error"
      event.context = "RootErrorBoundary"
    })
    console.error(error)
  }, [error])

  return <Error reset={reset} />
}
